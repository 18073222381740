// const apiHome = 'https://api.spacexdata.com/v3/dragons';

// const Home = async () => {
//   const get = await fetch(apiHome);
//   const home = await get.json();
//   return home;
// };

// export default Home;

const Home = [
  {
    firstName: 'Amrendra',
    lastName: 'Kumar',
    address: 'Mumbai',
    mobile: '9999999999',
    email: 'info@favyt.com',
  },
  {
    firstName: 'Akshaj',
    lastName: 'Kumar',
    address: 'Mumbai',
    mobile: '9999999998',
    email: 'info@favyt.com',
  },
];

export default Home;
