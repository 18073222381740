import studiolist from './apiStudio';
import { STUDIO_DATA } from '../types';

const initialState = [];

export const studioLists = () => async (dispatch) => {
  dispatch({
    type: STUDIO_DATA,
    payload: studiolist,
  });
};

const studioReducer = (state = initialState, action) => {
  switch (action.type) {
    case STUDIO_DATA:
      return action.payload;
    default:
      return state;
  }
};

export default studioReducer;
