import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { menLists } from '../../redux/men/Men';
import MenItem from './MenItem';
import './Men.css';

const Men = () => {
  const dispatch = useDispatch();
  const men = useSelector((state) => state.Men);
  useEffect(() => {
    if (men.length === 0) {
      dispatch(menLists());
    }
  });

  return (
    <div>
      <div>
        <h1>Welcome to Favyt : Your Favorite Men&apos;s Collections!!!</h1>
      </div>
      <ul className="exchange-list-conatiner">
        <h1 className="homepage-heading">List of Users</h1>
        <div className="card_container">
          {men.map((men) => (
            <MenItem
              key={men.mobile}
              men={men}
            />
          ))}
        </div>
      </ul>
    </div>
  );
};

export default Men;
