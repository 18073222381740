import React, { useEffect, useCallback } from 'react';
import {
  Route, Routes, Link, useLocation,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Home from './components/home/Home';
import Men from './components/men/Men';
import Women from './components/women/Women';
import Beauty from './components/beauty/Beauty';
import Studio from './components/studio/Studio';
import MyProfile from './components/myprofile/MyProfile';
import Login from './components/account_block/Login';
import Register from './components/account_block/Register';
import { logout } from './redux/account_block/accountActions';
import { clearMessage } from './redux/message_block/Message';
import EventBus from './common/EventBus';
import AuthVerify from './common/AuthVerify';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

const App = () => {
  const { isLoggedIn, user: currentUser } = useSelector((state) => state.Account);

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (['/login', '/register'].includes(location.pathname)) {
      dispatch(clearMessage()); // clear message when changing location
    }
  }, [dispatch, location]);

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    EventBus.on('logout', () => {
      logOut();
    });

    return () => {
      EventBus.remove('logout');
    };
  }, [logOut]);

  return (
    <div>
      <nav className="navbar navbar-expand navbar-dark bg-dark">
        <Link to="/" className="navbar-brand">
          Favyt Collections&apos; Hub
        </Link>
        <div className="navbar-nav collapse navbar-collapse">
          <li className="nav-item">
            <Link to="/Home" className="nav-link">
              Home
            </Link>
          </li>
          {isLoggedIn && (
            <li className="nav-item">
              <Link to="/men" className="nav-link">
                Men
              </Link>
            </li>
          )}
          {isLoggedIn && (
            <li className="nav-item">
              <Link to="/women" className="nav-link">
                Women
              </Link>
            </li>
          )}
          {isLoggedIn && (
            <li className="nav-item">
              <Link to="/beauty" className="nav-link">
                Beauty
              </Link>
            </li>
          )}
          {isLoggedIn && (
            <li className="nav-item">
              <Link to="/studio" className="nav-link">
                Studio
              </Link>
            </li>
          )}
        </div>

        {isLoggedIn ? (
          <div className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to="/myprofile" className="nav-link">
                Logged As:
                {' '}
                {currentUser.meta.email}
              </Link>
            </li>
            <li className="nav-item">
              <a href="/" className="nav-link" onClick={logOut}>
                Logout
              </a>
            </li>
          </div>
        ) : (
          <div className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to="/login" className="nav-link">
                Login
              </Link>
            </li>

            <li className="nav-item">
              <Link to="/register" className="nav-link">
                Sign Up
              </Link>
            </li>
          </div>
        )}
      </nav>

      <div className="container mt-3">
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/" element={<Home />} />
          <Route exact path="/men" element={<Men />} />
          <Route exact path="/women" element={<Women />} />
          <Route exact path="/beauty" element={<Beauty />} />
          <Route exact path="/studio" element={<Studio />} />
          <Route exact path="/myprofile" element={<MyProfile />} />
          <Route exact path="*" element={<Home />} />
        </Routes>
      </div>
      <AuthVerify logOut={logOut} />
    </div>
  );
};

export default App;
