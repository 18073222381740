import axios from 'axios';
import authHeader from '../../common/AuthHeader';
import { API_URL } from '../apis';

const getMyProfile = async () => {
  const result = await fetch(`${API_URL}account_block/accounts/show_profile`, { headers: { token: `${authHeader().token}` } });
  const response = await result.json();
  return [response.data];
};

const updateMyProfile = (userDetails) => axios
  .put(`${API_URL}account_block/accounts/update_profile`, {
    data: {
      attributes: {
        first_name: userDetails.first_name,
        last_name: userDetails.last_name,
        dob: userDetails.dob,
        gender: userDetails.gender,
        full_phone_number: userDetails.full_phone_number,
        platform: userDetails.platform,
        device_id: userDetails.device_id,
      },
      type: 'email_account',
    },
  }, { headers: { token: `${authHeader().token}` } });

export default {
  getMyProfile,
  updateMyProfile,
};
