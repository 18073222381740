import { legacy_createStore as createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';
import beautyReducer from './beauty/Beauty';
import homeReducer from './home/Home';
import menReducer from './men/Men';
import profileReducer from './myprofile/myProfileReducer';
import studioReducer from './studio/Studio';
import womenReducer from './women/Women';
import messageReducer from './message_block/Message';
import accountReducer from './account_block/accountReducer';

// Combine all reducers into one place
const rootReducer = combineReducers({
  Beauty: beautyReducer,
  Home: homeReducer,
  Men: menReducer,
  MyProfile: profileReducer,
  Studio: studioReducer,
  Women: womenReducer,
  Account: accountReducer,
  Message: messageReducer,
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk, logger)),
);

export default store;

export { rootReducer };
