import womenlist from './apiWomen';
import { WOMEN_DATA } from '../types';

const initialState = [];

export const womenLists = () => async (dispatch) => {
  dispatch({
    type: WOMEN_DATA,
    payload: womenlist,
  });
};

const womenReducer = (state = initialState, action) => {
  switch (action.type) {
    case WOMEN_DATA:
      return action.payload;
    default:
      return state;
  }
};

export default womenReducer;
