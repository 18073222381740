import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { beautyLists } from '../../redux/beauty/Beauty';
import BeautyItem from './BeautyItem';
import './Beauty.css';

const Beauty = () => {
  const dispatch = useDispatch();
  const beauty = useSelector((state) => state.Beauty);
  useEffect(() => {
    if (beauty.length === 0) {
      dispatch(beautyLists());
    }
  });

  return (
    <div>
      <div>
        <h1>Welcome to Favyt : Your Favorite Beauty Collections!!!</h1>
      </div>
      <ul className="exchange-list-conatiner">
        <h1 className="homepage-heading">List of Users</h1>
        <div className="card_container">
          {beauty.map((beauty) => (
            <BeautyItem
              key={beauty.mobile}
              beauty={beauty}
            />
          ))}
        </div>
      </ul>
    </div>
  );
};

export default Beauty;
