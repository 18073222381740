import menlist from './apiMen';
import { MEN_DATA } from '../types';

const initialState = [];

export const menLists = () => async (dispatch) => {
  dispatch({
    type: MEN_DATA,
    payload: menlist,
  });
};

const menReducer = (state = initialState, action) => {
  switch (action.type) {
    case MEN_DATA:
      return action.payload;
    default:
      return state;
  }
};

export default menReducer;
