/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Modal, Button, InputGroup } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { updateProfile } from '../../redux/myprofile/myProfileActions';

const ProfileModal = ({ show, onClose, profile }) => {
  const dispatch = useDispatch();
  const [isPending, setIsPending] = useState(false);
  const [person, setPerson] = useState({
    firstName: profile.first_name,
    lastName: profile.last_name,
    countryCode: profile.country_code,
    phoneNumber: profile.phone_number,
    dob: profile.dob,
  });

  function handleFirstNameChange(e) {
    setPerson({
      ...person,
      firstName: e.target.value,
    });
  }

  function handleLastNameChange(e) {
    setPerson({
      ...person,
      lastName: e.target.value,
    });
  }

  function handleCountryCodeChange(e) {
    setPerson({
      ...person,
      countryCode: e.target.value,
    });
  }

  function handlePhoneNumberChange(e) {
    setPerson({
      ...person,
      phoneNumber: e.target.value,
    });
  }

  function handleDobChange(e) {
    setPerson({
      ...person,
      dob: e.target.value,
    });
  }

  const saveChange = () => {
    setIsPending(true);
    const fullPhoneNumber = `${person.countryCode} + ${person.phoneNumber}`;
    const userDetails = {
      first_name: person.firstName,
      last_name: person.lastName,
      full_phone_number: fullPhoneNumber,
      dob: person.dob,
      platform: 'Web',
      updated_at: new Date(), // .toISOString().substring(0, 10),
    };
    dispatch(updateProfile(userDetails))
      .then(() => {
        setIsPending(false);
        onClose();
      })
      .catch(() => {
        setIsPending(false);
      });
  };

  return (
    <>
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <InputGroup size="sm" className="mb-3">
              <InputGroup.Text>
                First Name
              </InputGroup.Text>
              <Form.Control
                id="firstName"
                type="text"
                name="firstName"
                value={person.firstName}
                onChange={handleFirstNameChange}
                autoFocus
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
              />
            </InputGroup>

            <InputGroup size="sm" className="mb-3">
              <InputGroup.Text>
                Last Name
              </InputGroup.Text>
              <Form.Control
                id="lastName"
                type="text"
                name="lastName"
                value={person.lastName}
                onChange={handleLastNameChange}
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
              />
            </InputGroup>

            <InputGroup size="sm" className="mb-3">
              <InputGroup.Text>
                Country Code
              </InputGroup.Text>
              <Form.Control
                id="countryCode"
                type="number"
                name="countryCode"
                value={person.countryCode}
                onChange={handleCountryCodeChange}
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
              />
            </InputGroup>

            <InputGroup size="sm" className="mb-3">
              <InputGroup.Text>
                Phone Number
              </InputGroup.Text>
              <Form.Control
                id="phoneNumber"
                type="number"
                name="phoneNumber"
                value={person.phoneNumber}
                onChange={handlePhoneNumberChange}
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
              />
            </InputGroup>

            <InputGroup size="sm" className="mb-3">
              <InputGroup.Text>
                Birth Date
              </InputGroup.Text>
              <Form.Control
                id="dob"
                type="date"
                name="dob"
                value={person.dob}
                onChange={handleDobChange}
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
              />
            </InputGroup>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={saveChange}
          >
            {isPending && (
              <span className="spinner-border spinner-border-sm" />
            )}
            {isPending ? 'Saving...' : 'Save Changes'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProfileModal;
