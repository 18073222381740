import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { womenLists } from '../../redux/women/Women';
import WomenItem from './WomenItem';
import './Women.css';

const Women = () => {
  const dispatch = useDispatch();
  const women = useSelector((state) => state.Women);
  useEffect(() => {
    if (women.length === 0) {
      dispatch(womenLists());
    }
  });

  return (
    <div>
      <div>
        <h1>Welcome to Favyt : Your Favorite Women&apos;s Collections!!!</h1>
      </div>
      <ul className="exchange-list-conatiner">
        <h1 className="homepage-heading">List of Users</h1>
        <div className="card_container">
          {women.map((women) => (
            <WomenItem
              key={women.mobile}
              women={women}
            />
          ))}
        </div>
      </ul>
    </div>
  );
};

export default Women;
