/* eslint-disable import/no-named-as-default */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Navigate } from 'react-router-dom';
import { register } from '../../redux/account_block/accountActions';
import 'bootstrap/dist/css/bootstrap.min.css';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [successful, setSuccessful] = useState(false);
  const [loading, setLoading] = useState(false);

  const { message } = useSelector((state) => state.Message);

  const dispatch = useDispatch();

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleRegister = (event) => {
    event.preventDefault();
    setSuccessful(false);
    setLoading(true);
    const userDetails = {
      email,
      password,
    };
    dispatch(register(userDetails))
      .then(() => {
        setSuccessful(true);
      })
      .catch(() => {
        setSuccessful(false);
        setLoading(false);
      });
  };
  if (successful) {
    return (
      <Navigate to="/login" replace />
    );
  }
  return (
    <div className="col-md-12">
      <div className="card card-container">
        <Form onSubmit={handleRegister}>
          {!successful && (
            <div>
              <Form.Floating className="mb-4">
                <Form.Control
                  id="email"
                  name="email"
                  type="email"
                  placeholder="eaxmple@favyt.in"
                  value={email}
                  onChange={onChangeEmail}
                />
                <label htmlFor="email">Email address</label>
              </Form.Floating>
              <Form.Floating>
                <Form.Control
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={onChangePassword}
                />
                <label htmlFor="password">Password</label>
              </Form.Floating>

              <Form.Group as={Row} className="mb-3" controlId="formHorizontalCheck">
                <Col sm={{ span: 10, offset: 3 }}>
                  <Form.Check label="Remember me" />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3">
                <Col sm={{ span: 10, offset: 3 }}>
                  <Button type="submit">
                    {loading && (
                    <span className="spinner-border spinner-border-sm" />
                    )}
                    Register
                  </Button>
                </Col>
              </Form.Group>
            </div>
          )}
          {message && (
          <div className="form-group">
            <div className={successful ? 'alert alert-success' : 'alert alert-danger'} role="alert">
              {message}
            </div>
          </div>
          )}
        </Form>
      </div>
    </div>
  );
};

export default Register;
