import API from './myProfileAPI';

import {
  GET_PROFILE_DATA,
  UPDATE_PROFILE_SUCESS,
  UPDATE_PROFILE_FAIL,
  SET_MESSAGE,
} from '../types';

// let Loading = false;

export const getProfile = () => async (dispatch) => {
  // if (Loading) return;
  const myprofile = await API.getMyProfile();
  dispatch({
    type: GET_PROFILE_DATA,
    payload: myprofile,
  });
  // Loading = true;
};

export const updateProfile = (userDetails) => (dispatch) => API.updateMyProfile(userDetails).then(
  (data) => {
    dispatch({
      type: UPDATE_PROFILE_SUCESS,
      payload: [data.data.data],
    });
    return Promise.resolve();
  },
  (error) => {
    const message = (error.response
          && error.response.data
          && error.response.data.message)
        || error.message
        || error.toString();

    dispatch({
      type: UPDATE_PROFILE_FAIL,
    });

    dispatch({
      type: SET_MESSAGE,
      payload: message,
    });

    return Promise.reject();
  },
);
