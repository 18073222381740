/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Navigate } from 'react-router-dom';
import { login } from '../../redux/account_block/accountActions';
import 'bootstrap/dist/css/bootstrap.min.css';

const Login = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const { message } = useSelector((state) => state.Message);
  const { isLoggedIn } = useSelector((state) => state.Account);

  const dispatch = useDispatch();

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (event) => {
    event.preventDefault();
    setLoading(true);
    const userDetails = {
      email,
      password,
    };
    dispatch(login(userDetails))
      .then(() => {
        props.history.push('/myprofile');
        window.location.reload();
      })
      .catch(() => {
        setLoading(false);
      });
  };
  if (isLoggedIn) {
    return (
      <Navigate to="/myprofile" replace />
    );
  }
  return (
    <div className="col-md-12">
      <div className="card card-container">
        <Form onSubmit={handleLogin}>
          <div>
            <Form.Floating className="mb-4">
              <Form.Control
                id="email"
                name="email"
                type="email"
                placeholder="eaxmple@favyt.in"
                value={email}
                onChange={onChangeEmail}
              />
              <label htmlFor="email">Email Address</label>
            </Form.Floating>
            <Form.Floating>
              <Form.Control
                id="password"
                name="password"
                type="password"
                placeholder="Password"
                value={password}
                onChange={onChangePassword}
              />
              <label htmlFor="password">Password</label>
            </Form.Floating>
            <br />
            <Form.Group as={Row} className="mb-3">
              <Col sm={{ span: 10, offset: 4 }}>
                <Button type="submit">
                  {loading && (
                  <span className="spinner-border spinner-border-sm" />
                  )}
                  Login
                </Button>
              </Col>
            </Form.Group>
          </div>
          {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )}
        </Form>
      </div>
    </div>
  );
};

export default Login;
