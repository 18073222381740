import axios from 'axios';
import { API_URL } from '../apis';

const register = (userDetails) => axios.post(`${API_URL}account_block/accounts`, {
  data: {
    attributes: {
      email: userDetails.email,
      password: userDetails.password,
    },
    type: 'email_account',
  },
});

const login = (userDetails) => axios
  .post(`${API_URL}login_block/logins`, {
    data: {
      attributes: {
        email: userDetails.email,
        password: userDetails.password,
      },
      type: 'email_account',
    },
  })
  .then((response) => {
    if (response.data.meta.token) {
      localStorage.setItem('user', JSON.stringify(response.data));
    }
    return response.data;
  });

const logout = () => {
  localStorage.removeItem('user');
};

export default {
  register,
  login,
  logout,
};
