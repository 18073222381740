export const BEAUTY_DATA = '/beauty/BEAUTY_DATA';
export const HOME_DATA = '/home/HOME_DATA';
export const MEN_DATA = '/men/MEN_DATA';
export const STUDIO_DATA = '/studio/STUDIO_DATA';
export const WOMEN_DATA = '/women/WOMEN_DATA';
export const GET_PROFILE_DATA = '/myprofile/GET_PROFILE_DATA';
export const UPDATE_PROFILE_SUCESS = '/myprofile/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAIL = '/myprofile/UPDATE_PROFILE_FAIL';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const SET_MESSAGE = 'SET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
