// const apiWomen = 'https://api.spacexdata.com/v3/dragons';

// const Women = async () => {
//   const get = await fetch(apiWomen);
//   const women = await get.json();
//   return women;
// };

// export default Women;

const Women = [
  {
    firstName: 'Amrendra',
    lastName: 'Kumar',
    address: 'Mumbai',
    mobile: '9999999999',
    email: 'info@favyt.com',
  },
  {
    firstName: 'Akshaj',
    lastName: 'Kumar',
    address: 'Mumbai',
    mobile: '9999999998',
    email: 'info@favyt.com',
  },
];

export default Women;
