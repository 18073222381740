/* eslint-disable no-underscore-dangle */
import { useSelector, useDispatch } from 'react-redux';
import MyProfileItem from './MyProfileItem';
import { getProfile } from '../../redux/myprofile/myProfileActions';
import './MyProfile.css';

const MyProfile = () => {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.Account);
  const profile = useSelector((state) => state.MyProfile);
  if (isLoggedIn && profile.length === 0) {
    dispatch(getProfile());
  }

  return (
    <div>
      <div>
        <h1>Welcome to Favyt : It is My Profile!!!</h1>
      </div>
      <ul className="exchange-list-conatiner">
        <h1 className="homepage-heading">My Profile</h1>
        <div className="card_container">
          {profile.map((myprofile) => (
            <MyProfileItem
              key={myprofile.id}
              myprofile={myprofile.attributes}
            />
          ))}
        </div>
      </ul>
    </div>
  );
};

export default MyProfile;
