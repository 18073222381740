import beautylist from './apiBeauty';
import { BEAUTY_DATA } from '../types';

const initialState = [];

export const beautyLists = () => async (dispatch) => {
  dispatch({
    type: BEAUTY_DATA,
    payload: beautylist,
  });
};

const beautyReducer = (state = initialState, action) => {
  switch (action.type) {
    case BEAUTY_DATA:
      return action.payload;
    default:
      return state;
  }
};

export default beautyReducer;
