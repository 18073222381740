import React from 'react';
import PropTypes from 'prop-types';
import '../card_block/Card.css';

function BeautyItem(props) {
  const { beauty } = props;
  const {
    firstName, lastName, address, mobile, email,
  } = beauty;

  return (
    <li
      key={mobile}
    >
      <div className="card_home">
        <div className="card_home-content">
          Name:
          {' '}
          {firstName}
          {' '}
          {lastName}
        </div>
        <div className="card_home-content">
          Address:
          {' '}
          {address}
        </div>
        <div className="card_home-content">
          Mobile:
          {' '}
          {mobile}
        </div>
        <div className="card_home-content">
          E-Mail:
          {' '}
          {email}
        </div>
      </div>
    </li>
  );
}
export default BeautyItem;

BeautyItem.propTypes = {
  beauty:
    PropTypes.objectOf(
      {
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
        mobile: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
      },
    ).isRequired,
};
