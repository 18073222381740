import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { studioLists } from '../../redux/studio/Studio';
import StudioItem from './StudioItem';
import './Studio.css';

const Studio = () => {
  const dispatch = useDispatch();
  const studio = useSelector((state) => state.Studio);
  useEffect(() => {
    if (studio.length === 0) {
      dispatch(studioLists());
    }
  });

  return (
    <div>
      <div>
        <h1>Welcome to Favyt : Your Favorite Studio!!!</h1>
      </div>
      <ul className="exchange-list-conatiner">
        <h1 className="homepage-heading">List of Users</h1>
        <div className="card_container">
          {studio.map((studio) => (
            <StudioItem
              key={studio.mobile}
              studio={studio}
            />
          ))}
        </div>
      </ul>
    </div>
  );
};

export default Studio;
