import homelist from './apiHome';
import { HOME_DATA } from '../types';

const initialState = [];

export const homeLists = () => async (dispatch) => {
  dispatch({
    type: HOME_DATA,
    payload: homelist,
  });
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case HOME_DATA:
      return action.payload;
    default:
      return state;
  }
};

export default homeReducer;
