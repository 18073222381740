// const apiBeauty = 'https://api.spacexdata.com/v3/dragons';

// const Beauty = async () => {
//   const get = await fetch(apiBeauty);
//   const beauty = await get.json();
//   return beauty;
// };

// export default Beauty;

const Beauty = [
  {
    firstName: 'Amrendra',
    lastName: 'Kumar',
    address: 'Mumbai',
    mobile: '9999999999',
    email: 'info@favyt.com',
  },
  {
    firstName: 'Akshaj',
    lastName: 'Kumar',
    address: 'Mumbai',
    mobile: '9999999998',
    email: 'info@favyt.com',
  },
];

export default Beauty;
