/* eslint-disable camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../card_block/Card.css';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ProfileModal from './ProfileModal';

function MyProfileItem(props) {
  const [showModal, setShowModal] = useState(false);
  const { myprofile } = props;
  const {
    activated, country_code, first_name, last_name, dob, full_phone_number,
    phone_number, type, updated_at, platform, unique_auth_id, email,
  } = myprofile;

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const isActive = activated ? 'Active' : 'Inavtive';

  return (
    <>
      <li
        key={phone_number}
      >
        <div className="card_home">
          <div className="card_home-content">
            Name:
            {' '}
            {first_name}
            {' '}
            {last_name}
          </div>
          <div className="card_home-content">
            Country Code:
            {' '}
            {country_code}
          </div>
          <div className="card_home-content">
            Phone Number:
            {' '}
            {phone_number}
          </div>
          <div className="card_home-content">
            Full Phone Number:
            {' '}
            {full_phone_number}
          </div>
          <div className="card_home-content">
            E-Mail:
            {' '}
            {email}
          </div>
          <div className="card_home-content">
            Birth Date:
            {' '}
            {dob}
          </div>
          <div className="card_home-content">
            Status:
            {' '}
            {isActive}
          </div>
          <div className="card_home-content">
            Type:
            {' '}
            {type}
          </div>
          <div className="card_home-content">
            Platform:
            {' '}
            {platform}
          </div>
          <div className="card_home-content">
            Auth ID:
            {' '}
            {unique_auth_id}
          </div>
          <div className="card_home-content">
            Last Updated:
            {' '}
            {updated_at}
            <br />
            <Form.Group as={Row} className="mb-3">
              <Col sm={{ span: 10, offset: 4 }}>
                <Button variant="primary" onClick={openModal}>
                  {/* {loading && (
    <span className="spinner-border spinner-border-sm" />
    )} */}
                  Edit
                </Button>
              </Col>
            </Form.Group>
          </div>
        </div>
      </li>
      <ProfileModal show={showModal} onClose={closeModal} profile={myprofile} />

    </>
  );
}
export default MyProfileItem;

MyProfileItem.propTypes = {
  myprofile:
    PropTypes.objectOf(
      {
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
        country_code: PropTypes.string.isRequired,
        phone_number: PropTypes.string.isRequired,
        full_phone_number: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        dob: PropTypes.string.isRequired,
        activated: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        platform: PropTypes.string.isRequired,
        unique_auth_id: PropTypes.string.isRequired,
        updated_at: PropTypes.string.isRequired,
      },
    ).isRequired,
};
